import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';
import { Routes } from 'common/router/constants';
import { ReferralUseOfTerms } from 'domains/profile/containers/referral-program/containers/referral-use-of-terms';

const TITLE = 'Terms of Use';

const ReferralTermsOfUsePage: ApplicationPage = () => {
  return (
    <Page>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.ProfilePromo}
        enableMarginBottom
      />
      <ReferralUseOfTerms />
    </Page>
  );
};

ReferralTermsOfUsePage.getMeta = () => ({ title: TITLE });

export default ReferralTermsOfUsePage;
