import Loader from '@swe/shared/ui-kit/components/loader';
import PlainHtml from '@swe/shared/ui-kit/components/plain-html';
import Text from '@swe/shared/ui-kit/components/text';

import getReferralStatus from 'endpoints/profile/referral-program/get-referral-status';

const ReferralUseOfTerms = () => {
  const { data, isLoading } = getReferralStatus.useRequest();

  if (isLoading) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  if (!data?.terms) {
    return (
      <Text
        size="xl"
        adaptive
      >
        The referral program is currently unavailable for this store.
      </Text>
    );
  }

  return <PlainHtml html={data.terms} />;
};

export { ReferralUseOfTerms };
